import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import toast from "react-hot-toast";
import { ProjServices } from "../../apis/service/ProjServices";

export default function ExportData() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [projectExportData, setProjectExportData] = useState(null);
  const [selectedProject, setSelectedProject] = useState(0);
  const [availableProjects, setAvailableProjects] = useState([]);

  const getAllProjects = () => {
    ProjServices.UserProjects()
      .then((data) => {
        console.log(data);
        setAvailableProjects(data);
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      });
  };

  useEffect(() => {
    getAllProjects();
  }, []);
  const fetchProjectExportData = (start, end, project) => {
    let formdata = new FormData();
    formdata.append("start_date", start);
    formdata.append("end_date", end);
    formdata.append("project", project);
    ProjServices.ProjectsExport(formdata)
      .then((response) => {
        setProjectExportData(response.file);
        window.open(response.file, "_blank");
      })
      .catch((error) => {
        console.error("Error fetching project export data:", error);
      });
  };

  const handleFetchExportData = () => {
    if (!startDate || !endDate || selectedProject === 0) {
      toast.error(
        "Please select all fields: start date, end date, and project."
      );
    }

    fetchProjectExportData(startDate, endDate, selectedProject);
  };

  return (
    <div className={styles.container}>
      <h2>Export Projects Data</h2>
      <div className={styles.contentCard}>
        {/* Start Date and End Date inputs */}
        <div className={styles.dateInput}>
          <label>Start Date: </label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className={styles.dateInput}>
          <label>End Date: </label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>

        {/* Project selection (already implemented) */}
        <div className={styles.projectSelection}>
          <label>Select Project: </label>
          <select
            value={selectedProject}
            onChange={(e) => setSelectedProject(e.target.value)}
          >
            <option value={0}>Select your option</option>
            {availableProjects?.map((project) => (
              <option key={project.id} value={project.id}>
                {project.name}
              </option>
            ))}
          </select>
        </div>

        <button onClick={handleFetchExportData} className={styles.fetchBtn}>
          Fetch Export Data
        </button>

        {/* Display fetched export data */}
        {/* {projectExportData && (
          <div className={styles.exportData}>
            <h3>Exported Data:</h3>
            <pre>{JSON.stringify(projectExportData, null, 2)}</pre>
          </div>
        )} */}
      </div>
    </div>
  );
}
