import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import whatsappIcon from "../../assets/whatsapp.png";
import callIcon from "../../assets/call.png";
import arrow from "../../assets/arrow.png";
import { ProjectHourService } from "../../apis/service/ProjectHourServices";

export default function Employee() {
  const [hoursBox, setHoursBox] = useState();
  const [fromDate, setFromDate] = useState(getFormattedDate(new Date()));
  const [toDate, setToDate] = useState(getFormattedDate(new Date()));
  const [dates, setDates] = useState([]);
  const [isRange, setIsRange] = useState(false);
  const [expandedSection, setExpandedSection] = useState(null);

  useEffect(() => {
    setDates(generateDatesFromStartOfYear());
  }, []);

  const getHours = (from, to) => {
    ProjectHourService.getProjectsHours({ from, to })
      .then((res) => {
        setHoursBox(res);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (isRange) {
      getHours(fromDate, toDate);
    } else {
      getHours(fromDate, fromDate);
    }
  }, [fromDate, toDate, isRange]);

  const handleDateChange = (e) => {
    if (e.target.id === "fromDateSelect") {
      setFromDate(e.target.value);
      if (!isRange) {
        setToDate(e.target.value);
      }
    } else if (e.target.id === "toDateSelect") {
      setToDate(e.target.value);
    }
  };

  const handleRangeToggle = () => {
    setIsRange(!isRange);
    if (!isRange) {
      setToDate(fromDate);
    }
  };

  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  const totalSubmittedHours =
    hoursBox?.working_hours_people?.users_with_hours.length || 0;
  const totalNotSubmittedHours =
    hoursBox?.working_hours_people?.users_without_hours?.length || 0;

  return (
    <div>
      <div className={styles.dateSelector}>
        <label htmlFor="fromDateSelect">From Date:</label>
        <select
          id="fromDateSelect"
          value={fromDate}
          onChange={handleDateChange}
        >
          {dates.map((date) => (
            <option key={date} value={date}>
              {date}
            </option>
          ))}
        </select>

        {isRange && (
          <>
            <label htmlFor="toDateSelect">To Date:</label>
            <select
              id="toDateSelect"
              value={toDate}
              onChange={handleDateChange}
            >
              {dates.map((date) => (
                <option key={date} value={date}>
                  {date}
                </option>
              ))}
            </select>
          </>
        )}

        <button onClick={handleRangeToggle}>
          {isRange ? "Select Single Date" : "Select Date Range"}
        </button>
      </div>

      <div className={styles.tableContainer}>
        <div
          className={styles.tableCaption}
          onClick={() => toggleSection("submitted")}
        >
          Employee who submitted their hours ({totalSubmittedHours}) Out of (58){" "}
          <img src={arrow} alt="arrow" className={styles.toggleIcon} />
        </div>
        {expandedSection === "submitted" && (
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Employee</th>
                <th>Total Hours</th>
                <th>Projects</th>
              </tr>
            </thead>
            <tbody>
              {hoursBox?.working_hours_people?.users_with_hours.map((item) => (
                <tr key={item.employee__email}>
                  <td>{item.employee__username}</td>
                  <td>{item.total_hours}</td>
                  <td>
                    {item.projects.map((project, index) => (
                      <div key={index}>
                        {project.project_name}: {project.project_hour} hours
                      </div>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      <div className={styles.tableContainer}>
        <div
          className={styles.tableCaption}
          onClick={() => toggleSection("notSubmitted")}
        >
          Employee who didn't submit their hours ({totalNotSubmittedHours}) Out
          of (58)
          <img src={arrow} alt="arrow" className={styles.toggleIcon} />
        </div>
        {expandedSection === "notSubmitted" && (
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Department</th>
                <th>Contact</th>
                <th>WhatsApp</th>
              </tr>
            </thead>
            <tbody>
              {hoursBox?.working_hours_people?.users_without_hours?.map(
                (item) => (
                  <tr key={item[0]}>
                    <td>{item[0]}</td>
                    <td>{item[2]}</td>
                    <td>
                      <a href={`tel:${item[1]}`} rel="noreferrer">
                        <img src={callIcon} alt="call logo" />
                      </a>
                    </td>
                    <td>
                      <a
                        href={`https://wa.me/2${item[1]}?text=Please%20don't%20forget%20to%20log%20your%20hours.`}
                        rel="noreferrer"
                      >
                        <img src={whatsappIcon} alt="whatsapp logo" />
                      </a>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        )}
      </div>

      <div className={styles.tableContainer}>
        <div
          className={styles.tableCaption}
          onClick={() => toggleSection("onLeave")}
        >
          Employee On Vacation Or Sick Leave (
          {
            hoursBox?.working_hours_people?.users_with_day_off.filter(
              (item, index, self) =>
                self.findIndex(
                  (i) => i.employee__email === item.employee__email
                ) === index
            ).length // Count unique emails
          }
          )
          <img src={arrow} alt="arrow" className={styles.toggleIcon} />
        </div>
        {expandedSection === "onLeave" && (
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Email</th>
                <th>Department</th>
                <th>Contact</th>
                <th>WhatsApp</th>
              </tr>
            </thead>
            <tbody>
              {hoursBox?.working_hours_people?.users_with_day_off
                .filter(
                  (item, index, self) =>
                    self.findIndex(
                      (i) => i.employee__email === item.employee__email
                    ) === index // Filter unique emails
                )
                .map((item) => {
                  const { employee__email, employee__title, employee__phone } =
                    item;

                  return (
                    <tr key={employee__email}>
                      <td>{employee__email}</td>
                      <td>{employee__title}</td>
                      <td>
                        <a href={`tel:${employee__phone}`} rel="noreferrer">
                          <img src={callIcon} alt="call logo" />
                        </a>
                      </td>
                      <td>
                        <a
                          href={`https://wa.me/2${employee__phone}?text=Please%20don't%20forget%20to%20log%20your%20hours.`}
                          rel="noreferrer"
                        >
                          <img src={whatsappIcon} alt="whatsapp logo" />
                        </a>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

function getFormattedDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function generateDatesFromStartOfYear() {
  const startDate = new Date(new Date().getFullYear() - 1, 0, 1);
  const today = new Date();
  const dates = [];
  let currentDate = startDate;

  while (currentDate <= today) {
    dates.push(getFormattedDate(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
}
